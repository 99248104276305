import React, { Component } from 'react';

import './modal.scss';

class Modal extends Component {
  _displayHeaderByCategory = () => {
    switch (this.props.category) {
      case 'children':
        return <h3>Enfants de 6 à 12 ans</h3>;
      case 'teenager':
        return <h3>Ados de 12 ans et +</h3>;
      default:
        return <h3>Une erreur est survenue</h3>;
    }
  };
  _displayBodyByCategory = () => {
    switch (this.props.category) {
      case 'children':
        return (
          <div className="modal-body">
            <p>
              Les séances dédiées aux enfants sont plus courtes et durent 45 minutes. Elles sont
              animées par des activités corporelles, des exercices sensoriels, des relaxations et
              visualisations spécifiques, le tout adapté de manière attractive et ludique : jeux,
              contes…
              <br />
              <br />
              Les motifs de consultation les plus courants :
            </p>
            <ul className="left l">
              <li>Le sommeil perturbé</li>
              <li>Le stress scolaire et la pression</li>
              <li>Les débordements émotionnels, et notamment les colères</li>
              <li>L'agitation à l'école et/ou à la maison et le manque de concentration</li>
              <li>La timidité et les difficultés relationnelles</li>
              <li>L'anxiété, la nervosité et les angoisses</li>
              <li>Les maux, douleurs chroniques (maux de ventre, maux de tête, ...)</li>
              <li>Le manque de confiance en soi</li>
            </ul>
            <p>Les bénéfices de la sophrologie pour votre enfant</p>
            <ul className="left l">
              <li>Apprendre à se calmer, à se concentrer</li>
              <li>Avoir davantage confiance en soi</li>
              <li>S'endormir tranquillement, et se rendormir en cas de réveil nocturne</li>
              <li>Apprivoiser ses émotions (peur, colère, tristesse, ...)</li>
              <li>Développer son imagination et sa créativité</li>
              <li>Se sentir bien dans son corps</li>
            </ul>
          </div>
        );
      case 'teenager':
        return (
          <div className="modal-body">
            <p>Les motifs de consultation les plus courants :</p>
            <ul className="left l">
              <li>Le sommeil perturbé</li>
              <li>Le stress scolaire et la pression</li>
              <li>La timidité et les difficultés relationnelles</li>
              <li>L'anxiété, la nervosité et les angoisses</li>
              <li>La perte de valeurs, d'identité</li>
              <li>L'isolement, le repli sur soi</li>
              <li>Le manque d'estime de soi</li>
              <li>La préparation aux examens (brevet des collèges, bac, ...)</li>
              <li>Le passage du permis de conduire</li>
            </ul>
            <p>Les bénéfices de la sophrologie pour votre ado</p>
            <ul className="left l">
              <li>Apprendre à gérer les émotions qui le/la submerge</li>
              <li>Réduire le stress et les angoisses liés à cette étape de sa vie</li>
              <li>Avoir davantage confiance en soi</li>
              <li>Apaiser sa relation aux autres</li>
              <li>
                Développer son attention, sa concentration, ses capacités de mémoire et
                d'apprentissage
              </li>
              <li>Se sentir bien dans son corps qui évolue</li>
            </ul>
          </div>
        );
      default:
        return <p>Une erreur est survenue</p>;
    }
  };
  render() {
    return (
      <div className="modal">
        <div className="modal-box">
          <div className="modal-header">
            {this._displayHeaderByCategory()}
            <div className="close" onClick={this.props.toggle}>
              <i className="fas fa-times"></i>
            </div>
          </div>
          <div className="modal-body">{this._displayBodyByCategory()}</div>
        </div>
      </div>
    );
  }
}

export default Modal;

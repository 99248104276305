import React, { Component } from 'react'
import Slide from 'infinite-react-carousel'

import testimonials from '../../helpers/testimonials'

import './testimonial.scss'

class Testimonial extends Component {
  render() {
    const settings = {
      arrows: false,
      arrowsBlock: false,
      autoplay: true,
      autoplaySpeed: 60000,
      dots: true,
    }
    return (
      <section id="temoignages">
        <section className="b-white t">
          <div className="content-min">
            <h2 className="t-secondary">Témoignages</h2>
            <div className="testimonial-field">
              <Slide {...settings}>
                {testimonials.map((testimonial) => (
                  <article>
                    <div className="testimonial-header">
                      <h3>{testimonial.name}</h3>
                      <span>
                        {Array.from(Array(testimonial.rate), (e, i) => {
                          return <i className="fas fa-star" key={i}></i>
                        })}
                      </span>
                    </div>
                    <div className="testimonial-body">
                      <p>{testimonial.message}</p>
                    </div>
                  </article>
                ))}
              </Slide>
            </div>
          </div>
        </section>
      </section>
    )
  }
}

export default Testimonial

import React, { Component } from 'react';

class Workshop extends Component {
  render() {
    return (
      <section id="ateliers">
        <section className="b-primary t">
          <div className="content-min">
            <h2 className="right t-white">Atelier de numérologie</h2>
            <p className="right">
              Un atelier par mois environ, les dates sont disponibles 1 mois à l'avance sur ma page
              Facebook. L'atelier compte 6 participants maximum et se déroule dans mes locaux au 5
              rue du général de Gaulle, 68220 Hésingue de 9h à 14h30 avec deux pauses de 15/20min.
              <br />
              <br />
              Déroulement de l'atelier :
            </p>
            <ul className="right l">
              <li>Étude de la symbolique de chaque chiffre de 1 à 9</li>
              <li>Création de votre propre thème numérologique</li>
              <li>Interprétation personnalisée de votre thème</li>
            </ul>
            <p className="right">d'autres thèmes d'ateliers seront proposés ultérieurement.</p>
          </div>
        </section>
      </section>
    );
  }
}

export default Workshop;

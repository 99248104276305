import React from 'react';

import Header from './header/header';
import Home from './home/home';
import Session from './session/session';
import Workshop from './workshop/workshop';
import Testimonial from './testimonial/testimonial';
import Prices from './prices/prices';
import Contact from './contact/contact';
import Footer from './footer/footer';

import './app.scss';

function App() {
	return (
		<div className="App">
			<Header />
			<Home />
			<Session />
			<Workshop />
			<Testimonial />
			<Prices />
			<Contact />
			<Footer />
		</div>
	);
}

export default App;

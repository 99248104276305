import React, { Component } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Modal from '../modal/modal';

import './session.scss';

class Session extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      modalCategory: null,
    };
  }
  _toggleModal = (cat) => {
    if (!this.state.modalIsOpen) {
      this.setState({ modalIsOpen: true, modalCategory: cat });
    } else {
      this.setState({ modalIsOpen: false, modalCategory: null });
    }
  };
  _displayModal = () => {
    if (this.state.modalIsOpen) {
      return (
        <AnimatePresence>
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <Modal category={this.state.modalCategory} toggle={this._toggleModal} />
          </motion.div>
        </AnimatePresence>
      );
    }
  };
  render() {
    return (
      <section id="seances">
        {this._displayModal()}
        <section className="b-white t">
          <div className="content-min">
            <h2 className="t-primary">Déroulement d'une séance</h2>
            <p>
              Bien qu’elle soit différente des autres, la première séance est nécessaire pour
              établir un premier contact entre le professionnel et le patient afin de faire
              connaissance, de fixer les objectifs personnels et de découvrir la sophrologie grâce à
              une première pratique simple.
              <br />
              <br />
              Les séances suivantes se déroulent en trois étapes
            </p>
            <ul className="left l">
              <li>
                Nous faisons tout d’abord le point sur les évènements et vos impressions depuis la
                dernière séance.
              </li>
              <li>
                Nous passons ensuite à la pratique d'un exercice personnalisé selon vos besoins en
                position assise, yeux fermés. Guidé(e) par ma voix, vous devez seulement être à
                l'écoute de votre ressenti. C'est un moment de rencontre avec vous-même.
              </li>
              <li>Enfin, nous discutons et échangeons sur votre ressenti après l'exercice.</li>
            </ul>
            <p>Les pratiques sont enregistrées afin de les réaliser chez vous en autonomie.</p>
            <div className="details">
              <div onClick={() => this._toggleModal('children')}>
                <h3>Enfants de 6 à 12 ans</h3>
              </div>
              <div onClick={() => this._toggleModal('teenager')}>
                <h3>Ados de 12 ans et +</h3>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Session;

import React, { Component } from 'react'

class Prices extends Component {
  render() {
    return (
      <section id="tarifs">
        <section className="b-secondary t">
          <div className="content-min">
            <h2 className="t-white">Tarifs</h2>
            <p>
              Les tarifs des séances diffèrent en fonction de l'âge du patient.
              <br />
              Ils s'annoncent être de
            </p>
            <ul className="left l">
              <li>50€ l'heure pour les adultes</li>
              <li>40€ l'heure pour les enfants</li>
              <li>15€ par personne pour les séances de groupe (1h30 pour 8 personnes maximum tout les mercredis matin et un samedi par mois)</li>
            </ul>
            <p>Les tarifs des ateliers sont de 70€ par personne.</p>
          </div>
        </section>
      </section>
    )
  }
}

export default Prices

import React, { Component } from 'react';

import './contact.scss';

class Contact extends Component {
  render() {
    return (
      <section id="contact">
        <section className="b-white t">
          <div className="content-min">
            <h2 className="t-primary">Contact</h2>
            <div className="social-contact">
              <div className="social-field">
                <div className="social-icon">
                  <i className="far fa-clock"></i>
                </div>
                <div className="social-name">Toute la semaine sur RDV</div>
              </div>
              <div className="social-field">
                <a href="mailto:audreyschwartz68@hotmail.fr" aria-label="Adresse e-mail">
                  <div className="social-icon">
                    <i className="far fa-envelope"></i>
                  </div>
                </a>
                <div className="social-name">
                  <a href="mailto:audreyschwartz68@hotmail.fr">audreyschwartz68@hotmail.fr</a>
                </div>
              </div>
              <div className="social-field">
                <a href="tel:+33665959187">
                  <div className="social-icon" aria-label="Numéro de téléphone">
                    <i className="fas fa-phone-alt"></i>
                  </div>
                </a>
                <div className="social-name">
                  <a href="tel:+33665959187">06 65 95 91 87</a>
                </div>
              </div>
              <div className="social-field">
                <div className="social-icon" aria-label="Numéro de téléphone">
                  <i className="fas fa-map-marker-alt"></i>
                </div>
                <div className="social-name">5 Rue du Général de Gaulle, 68220 Hésingue</div>
              </div>
              <div className="social-field">
                <a
                  href="https://www.facebook.com/sophrologueaudreyschwartz"
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label="Page Facebook"
                >
                  <div className="social-icon">
                    <i className="fab fa-facebook-f"></i>
                  </div>
                </a>
                <div className="social-name">
                  <a
                    href="https://www.facebook.com/sophrologueaudreyschwartz"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Audrey Schwartz Sophrologue Harmonie Zen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default Contact;

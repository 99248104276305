import React, { Component } from 'react';

import './header.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      responsiveNavIsOpen: false,
    };
  }
  _toggleResponsiveNav = () => {
    let iconNav = document.getElementById('nav-icon4');
    let nav = document.querySelector('nav');
    if (this.state.responsiveNavIsOpen) {
      nav.classList.remove('open');
      iconNav.classList.remove('open');
      this.setState({ responsiveNavIsOpen: false });
    } else {
      iconNav.classList.add('open');
      nav.classList.add('open');
      this.setState({ responsiveNavIsOpen: true });
    }
  };
  _selectResponsiveNavCategory = () => {
    let iconNav = document.getElementById('nav-icon4');
    let nav = document.querySelector('nav');
    if (this.state.responsiveNavIsOpen) {
      nav.classList.remove('open');
      iconNav.classList.remove('open');
    }
  };
  render() {
    return (
      <header className="b-primary">
        <div className="content">
          <h1 className="t-white">Audrey Schwartz Sophrologue</h1>
          <div id="nav-icon4" onClick={this._toggleResponsiveNav}>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <nav>
            <ul className="t-white">
              <li onClick={this._selectResponsiveNavCategory}>
                <a href="#accueil">Accueil</a>
              </li>
              <li onClick={this._selectResponsiveNavCategory}>
                <a href="#seances">Séances</a>
              </li>
              <li onClick={this._selectResponsiveNavCategory}>
                <a href="#ateliers">Ateliers</a>
              </li>
              <li onClick={this._selectResponsiveNavCategory}>
                <a href="#temoignages">Témoignages</a>
              </li>
              <li onClick={this._selectResponsiveNavCategory}>
                <a href="#tarifs">Tarifs</a>
              </li>
              <li onClick={this._selectResponsiveNavCategory}>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;

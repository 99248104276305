import React, { Component } from 'react'

import './home.scss'
import meditation from '../../images/meditation.svg'

class Home extends Component {
  render() {
    return (
      <section id="accueil">
        <section className="b-primary">
          <div className="content-min">
            <div className="half">
              <h1>&laquo; Si je ne peux pas changer une situation, je peux en changer le sens &raquo;</h1>
              <a
                href="https://www.doctolib.fr/sophrologue/hesingue/audrey-schwartz?utm_campaign=website-button&amp;utm_source=audrey-schwartz-website-button&amp;utm_medium=referral&amp;utm_content=option-5&amp;utm_term=audrey-schwartz"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="button t-white b-secondary">Prendre rendez-vous</div>
              </a>
            </div>
            <div className="half">
              <img src={meditation} alt="Méditation" />
            </div>
          </div>
        </section>
        <section className="b-white t">
          <div className="content-min">
            <h2 className="t-secondary">Qu'est-ce que la sophrologie?</h2>
            <p className="t-tertiary">
              La sophrologie, créée par le neuropsychiatre Alfonso Caycedo en 1960, est une technique de relaxation basée sur la prise de conscience
              du corps. Elle contribue à l’amélioration du bien-être d'un individu ainsi que sa qualité de vie. La pratique régulière de la
              sophrologie amène à porter un nouveau regard sur soi afin d'acquérir une meilleure connaissance de soi-même, et permet également
              d'apprendre à surmonter les expériences de la vie quotidienne en développant une attitude plus positive. Son objectif premier est de
              maitriser le stress en prenant conscience de son corps par la vivance, c’est-à-dire vivre pleinement tout ce qui se vit en soi.
            </p>
          </div>
        </section>
        <section className="b-secondary t">
          <div className="content-min">
            <h2 className="t-white right">Les champs d'action de la sophrologie</h2>
            <ul className="right l">
              <li>Gestion du stress et des émotions/douleurs</li>
              <li>Développement de la confiance en soi et l'estime de soi</li>
              <li>Diminution de l'anxiété et l'angoisse</li>
              <li>Diminution des troubles du sommeil</li>
              <li>Accompagnement à la grossesse et préparation à l'accouchement</li>
              <li>Gestion d'un évènement difficile (examen, séparation, entretien d'embauche...)</li>
              <li>Accompagnement à la dépression et certaines pathologies lourdes (cancer...)</li>
              <li>Maîtrise des phobies/peurs</li>
              <li>Amélioration de la concentration (notamment chez les enfants)</li>
            </ul>
          </div>
        </section>
      </section>
    )
  }
}

export default Home

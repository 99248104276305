const testimonials = [
	{
		id: 1,
		name: 'Jean-Jacques',
		rate: 5,
		message:
			"Attaqué par un cancer sanquin rare et agressif, je devenais de plus en plus négatif, abattu, en voulant au monde entier, victime démoralisée. J'ai eu la chance, puis la volonté de rencontrer Madame Audrey Schwartz et ses séances de sophrologie. J'ai suivi fidèlement, régulièrement des séances, tout en faisant des exercices à la maison. J'ai découvert un monde nouveau, ensoleillé, salvateur. Peu à peu, avec confiance, mon état psychologique s'est amélioré. De victime, je suis devenu acteur de mon destin. Je suis devenu positif. J'ai appris à goûter l'instant présent pleinement. La vie est devenue plus facile pour mes proches. Je ne suis pas encore guéri de ce cancer mais vais mieux et surtout je positive, je suis serein. Je continue les séances de sophrologie car elles me restent agréables et bienfaisantes et je remercie Mme Audrey Schwartz. Quel sauvetage! quelle transformation!",
	},
	{
		id: 2,
		name: 'Nadine',
		rate: 5,
		message:
			"J'ai découvert la sophrologie il y a quelques années à une période extrêmement difficile de ma vie. J'ai eu la chance de pouvoir rencontrer Audrey Schwartz, une personne extraordinaire, avec qui j'ai entamé un long travail qui m'a permis de pouvoir me remettre en question... J'avoue que ça n'a pas été facile tout les jours. La bienveillance d'Audrey, les exercices que je pratique régulièrement ont totalement changé ma vie. Aujourd'hui je suis en paix avec moi-même. La sophrologie est indispensable à bon bien-être",
	},
	{
		id: 3,
		name: 'Clara',
		rate: 5,
		message:
			"Au travers de nos 16 séances et 16 mois, j'ai appris tant de choses au côtés d'Audrey. Grâce à son écoute, sa bienveillance et son professionnalisme, j'ai pu évoluer à mon rythme jusqu'à devenir une femme épanouie aujourd'hui, sur tout les plans. Que de chemin parcouru! Je lui en suis extrêmement reconnaissante et je tiens à lui exprimer toute ma gratitude. Je me sens désormais prête à voler de mes propres ailes, grâce à toutes les clés fournies par Audrey.",
	},
	{
		id: 4,
		name: 'Marie-Noelle',
		rate: 5,
		message:
			"Suite à la rencontre d'Audrey Schwartz lors du salon bien-être de Blotzheim en automne 2019 où j'ai fait une séance de sophrologie découverte (domaine que je ne connaissais pas). Aujourd'hui, grâce aux séances de sophrologie, aux exercices et aux conseils d'Audrey, j'arrive de plus en plus à lâcher prise et à vivre dans l'instant présent, l'ici et le maintenant. Merci beaucoup Audrey!",
	},
];

export default testimonials;

import React, { Component } from 'react';

import './footer.scss';

class Footer extends Component {
	render() {
		return (
			<footer>
				<section className="b-primary t">
					<div className="content">
						<p>
							&copy; 2020 Audrey Schwartz Sophrologue. Tout droits réservés.
							<br />
							<span>
								Réalisé avec <i className="far fa-heart"></i> par Tom Zidani
							</span>
						</p>
					</div>
				</section>
			</footer>
		);
	}
}

export default Footer;
